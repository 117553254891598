import React from "react";
import a1 from "../assets/RMS- Conference/IMG20240426125109_01.jpg";
import a2 from "../assets/RMS- Conference/IMG20240426125354_01.jpg";
import a3 from "../assets/RMS- Conference/IMG20240426125649.jpg";
import a4 from "../assets/RMS- Conference/IMG20240426130018_01.jpg";
import a5 from "../assets/RMS- Conference/IMG20240426130030_01.jpg";
import a6 from "../assets/RMS- Conference/IMG20240426130044_01.jpg";
import a7 from "../assets/RMS- Conference/IMG20240426132441_01.jpg";
import a8 from "../assets/RMS- Conference/IMG20240426132454_01.jpg";

import Footer from "./Footer";
import Header from "./Header";

const images = [
  { src: a3 },
  { src: a4 },
  { src: a5 },
  { src: a6 },
  { src: a7 },
  { src: a8 },
  { src: a1 },
  { src: a2 },
];

const Sessions_Rms = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid gallery-container">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="col-6 col-md-4 col-lg-3" key={index}>
              <div className="card border-0">
                <img
                  className="img-fluid gallery-img"
                  src={image.src}
                  alt={image.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sessions_Rms;
