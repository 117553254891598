import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Book from "./components/Book";
import Movies from "./components/Movies";
import News from "./components/News";
import Gallery from "./components/Gallery";
import GalleryVideos from "./components/GalleryVideos";
import GalleryImage from "./components/GalleryImage";
import { GalleryPoetry } from "./components/GalleryPoetry";
import GalleryMotivation from "./components/GalleryMotivation";
import GalleryInterviewVideo from "./components/GalleryInterviewVideo";
import GalleryIntervies from "./components/GalleryIntervies";
import Sessions from "./components/Sessions";
import Sessions_Dreams from "./components/Sessions_Dreams";
import Sessions_Rbsm from "./components/Sessions_Rbsm";
import Sessions_Rms from "./components/Sessions_Rms";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/book" element={<Book />} />
          <Route path="/movie" element={<Movies />} />
          <Route path="/news" element={<News />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/galleryvideos" element={<GalleryVideos />} />
          <Route path="/galleryimage" element={<GalleryImage />} />
          <Route path="/gallerypoetry" element={<GalleryPoetry />} />
          <Route path="/gallerymotivation" element={<GalleryMotivation />} />
          <Route
            path="/galleryinterviewvideos"
            element={<GalleryInterviewVideo />}
          />
          <Route path="/galleryinterview" element={<GalleryIntervies />} />
          <Route path="sessions" element={<Sessions />} />
          <Route path="/Sessions_Dreams" element={<Sessions_Dreams />} />
          <Route path="/Sessions_Rbsm" element={<Sessions_Rbsm />} />
          <Route path="/Sessions_Rms" element={<Sessions_Rms />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
