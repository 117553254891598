import React from "react";
import a1 from "../assets/about-3.jpg";
import a2 from "../assets/IMG_1329.JPG";
import a3 from "../assets/about-4.jpg";
import a4 from "../assets/IMG-20150410-WA0062.jpg";
import a5 from "../assets/8.jpg";
import a6 from "../assets/about-5.jpg";
import a7 from "../assets/aamir reading.jpg";
import a8 from "../assets/amir.jpg";
import a9 from "../assets/arunaroy.jpg";
import a10 from "../assets/IMG-20150423-WA0021_1429762966926.jpg";
import a11 from "../assets/about-7.jpg";
import a12 from "../assets/about-2.jpg";
import a13 from "../assets/about-8.jpg";
import a14 from "../assets/about-6.jpg";
import a15 from "../assets/about-9.jpg";
import Footer from "./Footer";
import Header from "./Header";

const images = [
  { src: a1, title: "Meeting with Amitabh Bachchan" },
  { src: a2, title: "Amitabh Bachchan signing Niharika book" },
  { src: a3, title: "Meeting with Shiv Khera" },
  { src: a4, title: "Presenting book to Shiv Khera" },
  { src: a5, title: "Presenting book to Anjali Bhagwat" },
  { src: a6, title: "Meeting with Aamir Khan on set of Satyamev Jayate" },
  { src: a7, title: "Presenting book to Aamir khan" },
  { src: a8, title: "Aamir Khan signing book" },
  { src: a9, title: "Presenting book to Aruna Roy" },
  { src: a10, title: "Meeting with Ravi Dubey" },
  { src: a11, title: "Meeting with Madhur Bhandarkar" },
  { src: a12, title: "Meeting with Eamin Haque Bobby" },
  { src: a13, title: "Meeting with Mukesh Chhabra" },
  { src: a14, title: "Meeting with Brahmanand" },
  { src: a15, title: "Meeting with Rajit" },
];

const GalleryImage = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid gallery-container">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="col-6 col-md-4 col-lg-3 mb-4" key={index}>
              <div className="card border-0">
                <a
                  href={image.src}
                  className="fancybox"
                  data-fancybox="gallery1"
                >
                  <img
                    className="img-fluid gallery-img"
                    src={image.src}
                    alt={image.title}
                  />
                </a>
                <div className="card-body">
                  <h6 align="justify">{image.title}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GalleryImage;
