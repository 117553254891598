import React from "react";
import a1 from "../assets/niharika.png";
import a2 from "../assets/ak.jpg";
import a3 from "../assets/create.png";
import a4 from "../assets/chemistry.png";
import a5 from "../assets/count.png";
import Footer from "./Footer";
import Header from "./Header";

const Book = () => {
  return (
    <div>
      <Header />
      <div className="container firstContainer">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card card-books">
              <div className="card-body" style={{height:"470px"}}>
                <h1 className="card__thumb">
                  <img src={a5} alt="Count Your Chicken Before You Lay Them" />
                </h1>
                <div className="card__body">
                  <p className="text-uppercase fw-bold">
                    Count your chicken before you lay them
                  </p>
                  <a
                    href="https://www.amazon.in/COUNT-YOUR-CHICKEN-BEFORE-THEM-ebook/dp/B00AXGX08U/ref=sr_1_1?ie=UTF8&qid=1551379362&sr=8-1&keywords=count+your+chicken+before+you+lay+them"
                    className="btn-books btn btn-outline-primary rounded-0"
                    id="btn_books"
                  >
                    Buy now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card card-books">
              <div className="card-body" style={{height:"470px"}}>
                <h1 className="card__thumb">
                  <img src={a1} alt="Niharika I Want to Live Again" />
                </h1>
                <div className="card__body">
                  <p className="text-uppercase fw-bold">
                    Niharika I want to live again
                  </p>
                  <a
                    href="https://www.amazon.in/Niharika-again-count-chicken-before-ebook/dp/B01NAOT48H/ref=sr_1_2?ie=UTF8&qid=1551379362&sr=8-2&keywords=count+your+chicken+before+you+lay+them"
                    className="btn-books btn btn-outline-primary rounded-0"
                    id="btn_books"
                  >
                    Buy now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card card-books">
              <div className="card-body" style={{height:"470px"}}>
                <h1 className="card__thumb">
                  <img src={a2} alt="Akanksha Ek Mahatvakanksha" />
                </h1>
                <div className="card__body">
                  <p className="text-uppercase fw-bold">
                    Akanksha ek mahatvakanksha
                  </p>
                  <a
                    href="#"
                    className="btn-books btn btn-outline-primary rounded-0"
                    id="btn_books"
                  >
                    Coming soon
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="card card-books">
              <div className="card-body" style={{height:"470px"}}>
                <h1 className="card__thumb">
                  <img src={a3} alt="Create Your Universe" />
                </h1>
                <div className="card__body">
                  <p className="text-uppercase fw-bold">Create your universe</p>
                  <a
                    href="#"
                    className="btn-books btn btn-outline-primary rounded-0"
                    id="btn_books"
                  >
                    Coming soon
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12" style={{marginTop:'20px'}}>
            <div className="card card-books">
              <div className="card-body" style={{height:"470px"}}>
                <h1 className="card__thumb">
                  <img src={a4} alt="The Chemistry of Diseases" />
                </h1>
                <div className="card__body">
                  <p className="text-uppercase fw-bold">
                    The Chemistry of Diseases
                  </p>
                  <a
                    href="#"
                    className="btn-books btn btn-outline-primary rounded-0"
                    id="btn_books"
                  >
                    Coming soon
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Book;
