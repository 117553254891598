import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import a1 from "../assets/ap.jpg";
import a2 from "../assets/interview by amit pandey.jpg";
import a3 from "../assets/interview by ayush kumar.jpg";

const GalleryInterviews = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid firstContainer">
        <div className="row mt-4">
          <div className="card col-xl-3 col-md-6 col-sm-12 border-0">
            <img
              className="img-fluid gallery-style "
              src={a1}
              alt="Interview by Prabhuprasad"
            />
            <div className="card-body">
              <h5 className="card-title">INTERVIEW BY PRABHUPRASAD</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://poetprabhu.in/interview-with-arun-prabhu/"
                className="btn btn-outline-primary"
              >
                View Interview
              </a>
            </div>
          </div>
          <div className="card col-xl-3 col-md-6 col-sm-12 border-0">
            <img
              className="img-fluid gallery-style"
              src={a2}
              alt="Interview by Amit Pandey"
            />
            <div className="card-body">
              <h5 className="card-title">INTERVIEW BY AMIT PANDEY</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://fragranceofwriting.blogspot.com/2015/01/an-interview-by-amit-pandey.html?spref=pi"
                className="btn btn-outline-primary"
              >
                View Interview
              </a>
            </div>
          </div>
          <div className="card col-xl-3 col-md-6 col-sm-12 border-0">
            <img
              className="img-fluid gallery-style "
              src={a3}
              alt="Interview by Ayush Kumar"
            />
            <div className="card-body">
              <h5 className="card-title mt-5">INTERVIEW BY AYUSH KUMAR</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://ayushkumar598.blogspot.com/2016/01/interview-with-author-arun-prabhu.html"
                className="btn btn-outline-primary"
              >
                View Interview
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GalleryInterviews;
