import React, { useState } from "react";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   const data = {
  //     name,
  //     email,
  //     subject,
  //     message,
  //   };

  //   const options = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(data),
  //   };

  //   fetch("/api/contact", options)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         setSuccessMessage(data.message);
  //         setSent(true);
  //         setLoading(false);
  //         setName("");
  //         setEmail("");
  //         setSubject("");
  //         setMessage("");
  //       } else {
  //         setError(true);
  //         setErrorMessage(data.message);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setError(true);
  //       setErrorMessage(error.message);
  //       setLoading(false);
  //     });
  // };

  const handleSubmit = () => {
    alert("Form Details Submited");
    window.location.reload();
  };

  return (
    <div>
      <footer className="bg-white mt-5" id="footer">
        <div className="container">
          <div className="row m-2">
            <div className="container col-lg-6 col-md-6 col-sm-12">
              <h2>SEND ME A MESSAGE</h2>
              <form className="contact_us_form row" onSubmit={handleSubmit}>
                <div className="form-group col-lg-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group col-lg-12">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group col-lg-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group col-lg-12">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>

                <div
                  className="form-group col-lg-12"
                  style={{ margin: "10px auto" }}
                >
                  <button
                    type="submit"
                    className="btn btn-outline-danger rounded-0"
                    disabled={loading}
                  >
                    {loading ? "SENDING..." : "SEND MESSAGE"}
                  </button>
                </div>

                {sent && (
                  <div className="alert alert-success col-lg-12">
                    {successMessage}
                  </div>
                )}
                {error && (
                  <div className="alert alert-danger col-lg-12">
                    {errorMessage}
                  </div>
                )}
                
              </form>
            </div>
            <div className="container col-lg-6 col-md-6 col-sm-12">
              <h4 style={{ marginTop: "20px" }}>CONTACT ME</h4>
              <div className="row">
                <div className="col-1 mt-1">
                  <i data-feather="map-pin"></i>
                </div>
                <div className="col-10">
                  <p>
                    301-B, 402-B, Flatted Factory Complex, Hartron Technology
                    Park Plot No. 25A, Electronic City, Sector 18, Gurugram,
                    Haryana 122015.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <i data-feather="phone"></i>
                </div>
                <div className="col-10">
                  <p>087500 60033</p>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <i data-feather="mail"></i>
                </div>
                <div className="col-10">
                  <p>arun@arunprabhu.com</p>
                </div>
              </div>
              <h4 style={{ marginTop: "10px" }}>FOLLOW ME ON</h4>

              <div className="icons" style={{ marginTop: "10px" }}>
                <a
                  target="_blank"
                  href="https://www.facebook.com/arunprabhuaradhya"
                  rel="noopener noreferrer"
                >
                  <i className="text-dark" data-feather="facebook"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/authorarun/"
                  rel="noopener noreferrer"
                >
                  <i className="text-dark" data-feather="instagram"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/author-arun-prabhu-aradhya/"
                  rel="noopener noreferrer"
                >
                  <i className="text-dark" data-feather="linkedin"></i>
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/auth_arunprabhu"
                  rel="noopener noreferrer"
                >
                  <i className="text-dark" data-feather="twitter"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCzr36VjVblFAmYqJcBVxRQg/videos"
                  rel="noopener noreferrer"
                >
                  <i className="text-dark" data-feather="youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <p
          className="p-3 bg-dark text-white text-center"
          style={{ color: "white", fontWeight: "400" }}
        >
          © All Rights Reserved with{" "}
          <a href="#" style={{ textDecoration: "none" }}>
            @ Arun Prabhu Aradhya
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
