import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Movies = () => {
  return (
    <div>
      <Header />
      <div class="container  firstContainer">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/SnJbl3P5d8g"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <Footer />
    </div>
  );
};

export default Movies;
