import React from "react";
import a1 from "../assets/Dream & Passion- Selected Pics/IMG_1132.JPG";
import a2 from "../assets/Dream & Passion- Selected Pics/IMG_1138.JPG";
import a6 from "../assets/Dream & Passion- Selected Pics/IMG_1152.JPG";

import a16 from "../assets/Dream & Passion- Selected Pics/IMG_1197.JPG";
import a20 from "../assets/Dream & Passion- Selected Pics/IMG_1216.JPG";
import a21 from "../assets/Dream & Passion- Selected Pics/IMG_1217.JPG";
import a22 from "../assets/Dream & Passion- Selected Pics/IMG_1220.JPG";
import a25 from "../assets/Dream & Passion- Selected Pics/IMG_1231.JPG";
import a26 from "../assets/Dream & Passion- Selected Pics/IMG_1235.JPG";
import a27 from "../assets/Dream & Passion- Selected Pics/IMG_1237.JPG";
import a28 from "../assets/Dream & Passion- Selected Pics/IMG_1244.JPG";
import a29 from "../assets/Dream & Passion- Selected Pics/IMG_1246.JPG";
import a30 from "../assets/Dream & Passion- Selected Pics/IMG_1253.JPG";

import a31 from "../assets/Dream & Passion- Selected Pics/IMG_1254.JPG";
import a32 from "../assets/Dream & Passion- Selected Pics/IMG_1257.JPG";
import a34 from "../assets/Dream & Passion- Selected Pics/IMG_1304.JPG";
import a35 from "../assets/Dream & Passion- Selected Pics/IMG_1314.JPG";
import a36 from "../assets/Dream & Passion- Selected Pics/IMG_1317.JPG";
import a38 from "../assets/Dream & Passion- Selected Pics/IMG_1330.JPG";
import a43 from "../assets/Dream & Passion- Selected Pics/IMG_1349.JPG";

import a48 from "../assets/Dream & Passion- Selected Pics/IMG_1359.JPG";
import a49 from "../assets/Dream & Passion- Selected Pics/IMG_1369.JPG";
import a50 from "../assets/Dream & Passion- Selected Pics/IMG_1380.JPG";
import a51 from "../assets/Dream & Passion- Selected Pics/IMG_1388.JPG";
import a52 from "../assets/Dream & Passion- Selected Pics/IMG_1414.JPG";
import a53 from "../assets/Dream & Passion- Selected Pics/IMG_1422.JPG";
import a54 from "../assets/Dream & Passion- Selected Pics/IMG_1423.JPG";
import a55 from "../assets/Dream & Passion- Selected Pics/IMG_1433.JPG";
import a56 from "../assets/Dream & Passion- Selected Pics/IMG_1434.JPG";
import a58 from "../assets/Dream & Passion- Selected Pics/IMG_1442.JPG";

import a63 from "../assets/Dream & Passion- Selected Pics/IMG_1482.JPG";
import a64 from "../assets/Dream & Passion- Selected Pics/IMG_1504.JPG";
import a66 from "../assets/Dream & Passion- Selected Pics/IMG_1516.JPG";

import a69 from "../assets/Dream & Passion- Selected Pics/IMG_1541.JPG";
import a70 from "../assets/Dream & Passion- Selected Pics/IMG_1574.JPG";

import a76 from "../assets/Dream & Passion- Selected Pics/WhatsApp Image 2024-02-23 at 10.47.55 PM.jpeg";

import Footer from "./Footer";
import Header from "./Header";

const images = [
  { src: a76 },
  { src: a1 },
  { src: a2 },
  { src: a6 },

  { src: a16 },
  { src: a20 },
  { src: a21 },
  { src: a26 },

  { src: a27 },
  { src: a28 },
  { src: a29 },
  { src: a31 },

  { src: a35 },
  { src: a38 },
  { src: a43 },
  { src: a48 },

  { src: a49 },
  { src: a51 },
  { src: a52 },
  { src: a53 },
  
  { src: a54 },
  { src: a55 },
  { src: a56 },
  { src: a63 },

  { src: a22 },
  { src: a25 },
  { src: a30 },
  { src: a32 },
  
  { src: a64 },
  { src: a66 },
  { src: a70 },
  { src: a69 },

  
  { src: a34 },
  { src: a36 },
  { src: a50 },
  { src: a58 },
];

const Sessions_Dreams = () => {

  return (
    <div>
      <Header />
      <div className="container-fluid gallery-container">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="col-6 col-md-4 col-lg-3" key={index} 
            style={{margin:'5px auto'}}
            >
              <div className="card border-0">
                <img
                  className="img-fluid gallery-img"
                  src={image.src}
                  alt={image.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sessions_Dreams;
