import React from "react";
import a1 from "../assets/news.jpeg";
import Header from "./Header";
import Footer from "./Footer";

const News = () => {
  return (
    <div>
      <Header />
      <div class="container-fluid firstContainer">
        <div class="row mt-4">
          <div class="item col-sm-6 col-md-3 mb-3">
            <a href={a1} class="fancybox" data-fancybox="gallery1">
              <img class="img-fluid" src={a1} width="100%" height="100%" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default News;
