import React, { useState, useEffect } from "react";
import a from "../../src/assets/a.png";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Header = () => {
  useEffect(() => {
    // Initialize feather icons
    window.feather.replace();

    // Scroll event listener
    const nav = document.querySelector("nav");
    const navBarFunction = () => {
      if (window.pageYOffset > 30) {
        nav.classList.add("bg-dark", "shadow");
        nav.classList.remove("random");
      } else {
        nav.classList.add("random");
        nav.classList.remove("bg-dark", "shadow");
      }
    };
    window.addEventListener("scroll", navBarFunction);
    return () => {
      window.removeEventListener("scroll", navBarFunction);
    };
  }, []);
  return (
    <nav className="navbar fixed-top navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand me-2" href="./index.php">
          <img
            className="img-fluid"
            src={a}
            alt="Author Arun Prabhu Aradhya"
            loading="lazy"
            style={{ maxHeight: "60px" }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i data-feather="menu"></i>
        </button>
        <nav className="navbar fixed-top navbar-expand-lg">
          <div className="container-fluid">
            <a className="navbar-brand me-2" href="/">
              <img
                className="img-fluid"
                src={a}
                alt="Author Arun Prabhu Aradhya"
                loading="lazy"
                style={{ maxHeight: "60px" }}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i data-feather="menu"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav " style={{ marginLeft: "250px" }}>
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="/">
                    HOME
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    ABOUT
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    href="https://arunprabhuaradhya.blogspot.com/"
                  >
                    BLOG
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/book">
                    BOOKS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/movie">
                    MOVIES
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="dropdownEngagementButton"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    ENGAGEMENTS
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownEngagementButton"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href="https://www.amosta.com/"
                      >
                        AMOSTA SOLUTIONS
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href="http://speaktochange.in/"
                      >
                        SPEAK TO CHANGE FOUNDATION
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href="https://sartracglobal.com/"
                      >
                        SARTRAC GLOBAL
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        target="_blank"
                        href="http://www.akankshaaradhyafoundation.com/"
                      >
                        AKANKSHA ARADHYA FOUNDATION
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/news">
                    NEWS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/gallery">
                    GALLERY
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/sessions">
                  SESSIONS
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#footer">
                    CONTACT
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </nav>
  );
};

export default Header;
