import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const GalleryVideos = () => {
  return (
    <div>
      <Header />
      <div class="container-fluid firstContainer">
        <div class="row mt-4">
          <div class="item col-lg-3 col-md-6 col-sm-12">
            <iframe
              src="https://www.youtube.com/embed/260-0pLxSJc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="item col-lg-3 col-md-6 col-sm-12">
            <iframe
              src="https://www.youtube.com/embed/S2digzHF6Ys"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="item col-lg-3 col-md-6 col-sm-12">
            <iframe
              src="https://www.youtube.com/embed/cbqjesWBblw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="item col-lg-3 col-md-6 col-sm-12">
            <iframe
              src="https://www.youtube.com/embed/to33Em6uyN8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="item col-lg-3 col-md-6 col-sm-12">
            <iframe
              src="https://www.youtube.com/embed/QpWWaMNoJOI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="item col-lg-3 col-md-6 col-sm-12">
            <iframe
              src="https://www.youtube.com/embed/nZt3QiIXZlg"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="item col-lg-3 col-md-6 col-sm-12">
            <iframe
              src="https://www.youtube.com/embed/KlG89teWNXc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GalleryVideos;
