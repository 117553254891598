import React from "react";
import Header from "./Header";
import a1 from "../assets/arun sir.png";
import Footer from "./Footer";

const About = () => {
  return (
    <div>
      <Header />
      <div>
        <div
          className="container-fluid"
          style={{ backgroundColor: "#8adc98ad" }}
        >
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
              <h3 className="text-center aboutmng">ARUN PRABHU</h3>
              <h3 className="text-center" style={{ fontSize: "60px" }}>
                "ARADHYA"
              </h3>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
              <img className="img-fluid" src={a1} alt="" />
            </div>
          </div>
        </div>
        <div className="container text-center bg-light mt-5">
          <h3 className="text-center display-3">About Me</h3>

          <p align="justify">
            Arun Prabhu is a mechanical engineer by education, an IT person by
            profession, and an author, movie maker, theatre artist, speaker, and
            life coach by passion.
          </p>
          <p align="justify">
            He has written 2 books: "Count Your Chicken Before You Lay Them" and
            "Niharika I Want to Live Again," both of which have been widely
            accepted with 5-star ratings. He is also working on 3 books:
            "Akanksha Ek Mehatvakanksha," "Create Your Universe," and "The
            Chemistry of Diseases." He is a mentor and coach and the Founder of
            Sartrac Global, which aims to help people live a holistic life. He
            believes that financial and emotional wellness is an integral part
            of physical wellness. He is also the Founder of the Akanksha Aradhya
            Foundation, which promotes four initiatives: Arogyam (living a
            holistic, disease-free life without medicines), reviving the Gurukul
            education system, Annapoorna (feeding the needy), and Palna
            (adopting abandoned girl children and giving them a home and
            family).
          </p>
          <button
            type="button"
            className="btn btn-outline-info rounded-0 mb-5"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Read more
          </button>

          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
              <div className="modal-content">
                <div className="modal-header bg-dark text-white">
                  <h5 className="modal-title mx-auto" id="exampleModalLabel">
                    About Me
                  </h5>
                  <button
                    type="button"
                    className="btn-close position-absolute bg-danger rounded-circle"
                    data-bs-dismiss="modal"
                    style={{ right: "15px" }}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body ">
                  <p align="justify">
                    Arun Prabhu is a mechanical engineer by education, an IT
                    person by profession, and an author, movie maker, theatre
                    artist, speaker, and life coach by passion.
                  </p>
                  <p align="justify">
                    He has written 2 books: "Count Your Chicken Before You Lay
                    Them" and "Niharika I Want to Live Again," both of which
                    have been widely accepted with 5-star ratings. He is also
                    working on 3 books: "Akanksha Ek Mehatvakanksha," "Create
                    Your Universe," and "The Chemistry of Diseases." He is a
                    mentor and coach and the Founder of Sartrac Global, which
                    aims to help people live a holistic life. He believes that
                    financial and emotional wellness is an integral part of
                    physical wellness. He is also the Founder of the Akanksha
                    Aradhya Foundation, which promotes four initiatives: Arogyam
                    (living a holistic, disease-free life without medicines),
                    reviving the Gurukul education system, Annapoorna (feeding
                    the needy), and Palna (adopting abandoned girl children and
                    giving them a home and family).
                  </p>
                  <p align="justify">
                    He is also the trustee of Speak To Change Foundation, which
                    provides a platform for aspiring speakers to express
                    themselves, thereby empowering and elevating them.
                  </p>
                  <p align="justify">
                    He is also a part of the Amosta Group, an IT services
                    organization that provides a platform for the Internet of
                    Everything through its products and services. Through their
                    HumHai vertical, they aim to provide opportunities in
                    employment and self-employment. He is a strong believer and
                    promoter of the Law of Attraction and conducts workshops
                    online and offline. He has been invited by many colleges and
                    corporates to motivate, guide, and mentor them. Future plans
                    include setting up Arogyam and Gurukul centers across the
                    globe to lay the foundation for a responsible and just
                    society.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
