import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import Banner from '../assets/Dream & Passion- Selected Pics/WhatsApp Image 2024-02-20 at 7.58.37 PM.jpeg'
import Banner1 from '../assets/RMS- Conference/a1.jpeg'
import Banner2 from '../assets/RBSM- DSLR Images/shaping your life.jpeg'

const Sessions = () => {
  return (
    <div>
      <Header />
      <div class="container-fluid firstContainer">
        <div class="row" style={{margin:'100px auto'}}>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <img
              class="img-fluid gallery-style card-img-top"
              src={Banner}
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title mt-3">Dream & Passion</h5>
              <a href="/Sessions_Dreams" class=" btn btn-outline-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <img src={Banner1}  class="img-fluid gallery-style card-img-top"/>
            <div class="card-body">
              <h5 class="card-title mt-3">RMS- Conference</h5>
              <a href="/Sessions_Rbsm" class=" btn btn-outline-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
          <img src={Banner2}  class="img-fluid gallery-style card-img-top"/>
            <div class="card-body">
              <h5 class="card-title mt-3">RBSM- Shaping your life</h5>
              <a href="/Sessions_Rms" class=" btn btn-outline-primary ">
                Full Album
              </a>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sessions;
