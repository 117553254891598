import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const GalleryInterviewVideo = () => {
  return (
    <div>
      <Header />
      <div class="container-fluid firstContainer">
        <div class="row mt-4">
          <div class="item col-lg-6 col-md-12 col-sm-12">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/sZBwG8Qfuh4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="item col-lg-6 col-md-12 col-sm-12">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/MIclzrkKJEY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="item col-lg-6 col-md-12 col-sm-12">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/OXzR_pHNUDY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GalleryInterviewVideo;
