import React from "react";
import a1 from "../assets/about-1.jpg";
import a2 from "../assets/about-2.jpg";
import a3 from "../assets/about-3.jpg";
import a4 from "../assets/about-4.jpg";
import a5 from "../assets/about-5.jpg";
import a6 from "../assets/about-6.jpg";
import a7 from "../assets/about-7.jpg";
import a8 from "../assets/about-8.jpg";
import a9 from "../assets/about-9.jpg";
import a10 from "../assets/Intro-1.mp4";
import a11 from "../assets/Arun prabhu -portrait banner.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const HeaderVider = () => {
  return (
    <div>
      <div className="video-back">
        <video
          id="introVideo"
          className="index-video d-none d-md-block"
          loop
          muted
          autoPlay
        >
          <source src={a10} type="video/mp4" />
        </video>
        <img
          src={a11}
          className="d-block d-md-none img-fluid"
          alt="author arun prabhu aradhya"
        />
      </div>

      <div className="portfolio bg-dark">
        <div className="container text-center">
          <h1 className="text-warning">GALLERY</h1>

          <OwlCarousel
            className="owl-theme mt-5"
            loop
            margin={15}
            nav
            items={3}
            autoplay
            autoplayTimeout={3000}
          >
            <div className="item">
              <img className="img-fluid carousal-img" src={a1} alt="Slide 1" />
            </div>
            <div className="item">
              <img className="img-fluid carousal-img" src={a2} alt="Slide 2" />
            </div>
            <div className="item">
              <img className="img-fluid carousal-img" src={a3} alt="Slide 3" />
            </div>
            <div className="item">
              <img className="img-fluid carousal-img" src={a4} alt="Slide 4" />
            </div>
            <div className="item">
              <img className="img-fluid carousal-img" src={a5} alt="Slide 5" />
            </div>
            <div className="item">
              <img className="img-fluid carousal-img" src={a6} alt="Slide 6" />
            </div>
            <div className="item">
              <img className="img-fluid carousal-img" src={a7} alt="Slide 7" />
            </div>
            <div className="item">
              <img className="img-fluid carousal-img" src={a8} alt="Slide 8" />
            </div>
            <div className="item">
              <img className="img-fluid carousal-img" src={a9} alt="Slide 9" />
            </div>
          </OwlCarousel>
          
        </div>
      </div>
    </div>
  );
};
export default HeaderVider;
