import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import a1 from "../assets/Candle flame -final.mp4";
import a2 from "../assets/Exciting Moments.mp4";
import a3 from "../assets/Zindagi bhar ki seekh nhi.mp4";

export const GalleryPoetry = () => {
  return (
    <div>
      <Header />
      <div class="container-fluid firstContainer">
        <div class="row mt-4">
          <div class="item col-sm-6 col-md-3 mb-3">
            <a href={a1} class="fancybox" data-fancybox="gallery1">
              <video src={a1} width="100%" height="100%"></video>
            </a>
          </div>
          <div class="item col-sm-6 col-md-3 mb-3">
            <a href={a2} class="fancybox" data-fancybox="gallery1">
              <video src={a2} width="100%" height="100%"></video>
            </a>
          </div>

          <div class="item col-sm-6 col-md-3 mb-3">
            <a href={a3} class="fancybox" data-fancybox="gallery1">
              <video src={a3} width="100%" height="100%"></video>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
