import React from "react";
import Header from "./Header";
import HeaderVider from "./HeaderVider";
import Footer from "./Footer";

const Home = () => {
  return (
    <div>
      <Header />
      <HeaderVider />
      <Footer />
    </div>
  );
};

export default Home;
